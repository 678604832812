<template>
  <app-selector all-apps />
  <div class="polls-view">
    <paginated-panel :items="displayedPolls">
      <template #header>
        <div class="pagination-header">
          <selector-switch v-model="dateFilter" :values="possibleDateFilters" />
          <button class="main-button second-accent" @click="openEditor()"> <v-svg h="1.4em" name="add" /> Nouveau sondage </button>
        </div>
      </template>
      <template #content="slotProps">
        <poll :poll="slotProps.item" @edit="openEditor(slotProps.item)" @delete="deletePoll(slotProps.item)" />
      </template>
    </paginated-panel>
  </div>
  <poll-editor v-if="editorOpened"
               :poll="editionItem"
               @delete="deletePoll"
               @save="savePoll"
               @close="closeEditor" />
</template>

<script>
  import AppSelector from '@/components/AppSelector'
  import PaginatedPanel from '@/components/PaginatedPanel'
  import Poll from '@/components/poll/Poll'
  import PollEditor from '@/components/poll/PollEditor'

  import { computed, readonly, ref, watch } from 'vue'
  import usePollStore from '@/store/poll.store'
  import useApplicationStore from '@/store/application.store'
  import useModalEditor from '@/mixins/modalEditor'

  const DateFilters = [
    { id: 'current', label: 'En cours' },
    { id: 'next', label: 'À venir' },
    { id: 'previous', label: 'Passé' }
  ]

  export default {
    name: 'Polls',
    components: { AppSelector, PaginatedPanel, Poll, PollEditor },
    setup() {
      const pollStore = usePollStore()
      const applicationStore = useApplicationStore()
      const modalEditor = useModalEditor()

      const possibleDateFilters = readonly(DateFilters)
      const dateFilter = ref('current')

      const { selectedAppId } = applicationStore
      const displayedPolls = computed(() =>
        pollStore[`${dateFilter.value}Polls`].value.filter(n => !selectedAppId.value || n.apps.includes(selectedAppId.value))
      )

      const fetchPolls = () => {
        const upper = dateFilter.value[0].toUpperCase() + dateFilter.value.slice(1)
        pollStore[`fetch${upper}Polls`]()
      }

      watch(dateFilter, () => fetchPolls(), { immediate: true })

      const savePoll = async poll => {
        if (modalEditor.editionItem.value) await pollStore.updatePoll(poll)
        else await pollStore.addPoll(poll)
        fetchPolls()
        modalEditor.closeEditor(true)
      }

      const deletePoll = async poll => {
        if (confirm(`Supprimer le sondage ${poll.title} ?`)) await pollStore.deletePoll(poll)
        fetchPolls()
        modalEditor.closeEditor(true)
      }

      return {
        possibleDateFilters,
        dateFilter,
        displayedPolls,
        savePoll,
        deletePoll,
        ...modalEditor
      }
    }
  }
</script>

<style lang="scss" scoped>
  .polls-view {
    padding: 2rem;
    .pagination-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
</style>

<template>
  <div class="poll">
    <div class="app">
      <app-logo class="bg-grey" :application="poll.app" />
    </div>
    <div class="content">
      <div class="left">
        <div class="title">{{ poll.title }}</div>
        <div class="description">{{ poll.description }}</div>
        <div class="actions">
          <button class="inline-button main-accent" @click="$emit('edit')"><v-svg h="1.4em" name="edit" /> Modifier</button>
          <button class="inline-button destructive" @click="$emit('delete')"> <v-svg h="1.4em" name="delete" /> Supprimer</button>
        </div>
      </div>
      <div class="right">
        <div class="dates">{{ dates }}</div>
        <div class="chart-values">
          <div class="chart">
            <div class="downvotes"><v-svg name="thumb-down" /></div>
            <div class="bar">
              <div class="negative-bar" :style="{width : `${(50 - score)*2}%`}" />
              <div class="positive-bar" :style="{width : `${(score - 50)*2}%`}" />
            </div>
            <div class="upvotes"><v-svg name="thumb-up" /></div>
          </div>
          <div class="values">
            <div class="downvotes">{{ poll.downVote }}</div>
            <div class="percent">{{ score }}%</div>
            <div class="upvotes">{{ poll.upVote }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { DateTime } from 'luxon'

  export default {
    name: 'Poll',
    props: {
      poll: {
        type: Object,
        required: true
      }
    },
    emits: ['edit', 'delete'],
    setup(props) {
      const dates = computed(() => {
        const toFormat = millis => DateTime.fromMillis(millis).toFormat(`dd/MM/yyyy HH'h'mm`)
        return `du ${toFormat(props.poll.start)} au ${toFormat(props.poll.end)}`
      })

      const score = computed(() => {
        let { downVote, upVote } = props.poll
        return Math.round((upVote * 100) / (upVote + downVote))
      })

      return {
        dates,
        score
      }
    }
  }
</script>

<style lang="scss" scoped>
  .poll {
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: 1rem;
    min-height: 7.6rem;
    margin: 0.5rem 0;
    .content {
      display: grid;
      grid-template-columns: 1fr 18rem;
      .title {
        @extend %font-title-5;
      }
      .description {
        color: hsla(var(--hsla-black), 0.7);
      }
      .actions {
        display: inline-flex;
        align-items: center;
        padding: 8px 0 16px 0;
        border-bottom: 1px solid hsla(var(--hsla-black), 0.1);
        .inline-button {
          padding-left: 0;
        }
      }
      .dates {
        color: hsla(var(--hsla-black), 0.5);
        margin-bottom: 16px;
      }
      .chart-values {
        .chart {
          display: flex;
          align-items: center;
          .bar {
            height: 4px;
            width: 100%;
            border-radius: 3px;
            background: #c4c4c4;
            margin: 0 8px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .negative-bar {
              justify-self: end;
              background-color: var(--color-second-accent);
              border-radius: 3px 0 0 3px;
            }
            .positive-bar {
              justify-self: start;
              height: 100%;
              background-color: var(--color-main-accent);
              border-radius: 0 3px 3px 0;
            }
          }
          .downvotes {
            color: var(--color-second-accent);
          }
          .upvotes {
            color: var(--color-main-accent);
          }
        }
        .values {
          display: grid;
          grid-template-columns: 20% 1fr 20%;
          align-items: end;
          .upvotes,
          .downvotes {
            @extend %font-caption-2;
            color: hsla(var(--hsla-black), 0.5);
          }
          .percent {
            @extend %font-body-bold;
            justify-self: center;
            &.positive {
              color: var(--color-main-accent);
            }
            &.negative {
              color: var(--color-second-accent);
            }
          }
          .upvotes {
            justify-self: end;
          }
        }
      }
    }
  }
</style>

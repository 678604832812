import { extendWithAuthorization, http } from '@/http'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { reactive, readonly, toRefs } from 'vue'

const db = firebase.firestore()

const state = reactive({
  currentPolls: [],
  nextPolls: [],
  previousPolls: []
})

export default function() {
  const resetStates = () => {
    state.currentPolls = []
    state.nextPolls = []
    state.previousPolls = []
  }

  const getVotes = async proposal => {
    const querySnapshot = await db.collection(`SMARTLIKE/DATA/PROPOSALS/${proposal.id}/votes`).get()
    const documents = querySnapshot.docs

    let upVote = 0,
      downVote = 0
    for (const doc of documents) {
      upVote += doc.get('upVote') ?? 0
      downVote += doc.get('downVote') ?? 0
    }
    return { ...proposal, upVote, downVote }
  }

  const fetchCurrentPolls = () => {
    if (!state.currentPolls.length)
      db.collection('SMARTLIKE/DATA/PROPOSALS')
        .where('end', '>=', new Date())
        .orderBy('end', 'desc')
        .get()
        .then(async querySnap => {
          let polls = querySnap.docs
            .map(doc => {
              const data = doc.data()
              const start = data.start.toMillis()
              const end = data.end.toMillis()
              if (new Date() > new Date(start)) return { ...data, id: doc.id, start, end }
            })
            .filter(p => p)
          const promises = polls.map(p => getVotes(p))
          state.currentPolls = await Promise.all(promises)
        })
  }

  const fetchPreviousPolls = () => {
    if (!state.previousPolls.length)
      db.collection('SMARTLIKE/DATA/PROPOSALS')
        .where('start', '<=', new Date())
        .orderBy('start', 'desc')
        .get()
        .then(async querySnap => {
          let polls = querySnap.docs
            .map(doc => {
              const data = doc.data()
              const start = data.start.toMillis()
              const end = data.end.toMillis()
              if (new Date() > new Date(end)) return { ...data, id: doc.id, start, end }
            })
            .filter(p => p)
          const promises = polls.map(p => getVotes(p))
          state.previousPolls = await Promise.all(promises)
        })
  }

  const fetchNextPolls = () => {
    if (!state.nextPolls.length)
      db.collection('SMARTLIKE/DATA/PROPOSALS')
        .where('start', '>', new Date())
        .orderBy('start', 'asc')
        .get()
        .then(async querySnap => {
          let polls = querySnap.docs.map(doc => {
            const data = doc.data()
            const start = data.start.toMillis()
            const end = data.end.toMillis()
            return { ...data, id: doc.id, start, end }
          })
          const promises = polls.map(p => getVotes(p))
          state.nextPolls = await Promise.all(promises)
        })
  }

  const updatePoll = async({ id, ...poll }) => {
    const start = new Date(poll.start)
    const end = new Date(poll.end)
    await db.doc(`SMARTLIKE/DATA/PROPOSALS/${id}`).set({ ...poll, start, end }, {merge: true})
    resetStates()
  }

  const addPoll = async poll => {
    const extendedHttp = await extendWithAuthorization(http)
    await extendedHttp.put('like/proposal', { json: poll })
    resetStates()
  }

  const deletePoll = async({ id }) => {
    await db.doc(`SMARTLIKE/DATA/PROPOSALS/${id}`).delete()
    resetStates()
  }

  return {
    ...toRefs(readonly(state)),
    fetchCurrentPolls,
    fetchPreviousPolls,
    fetchNextPolls,
    updatePoll,
    addPoll,
    deletePoll
  }
}
